import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import ScratchLinkScanningStepComponent from '../components/connection-modal/scratchLinkScanning-step.jsx';
import VM from 'scratch3-vm-scratchlink';

class ScratchLinkScanningStep extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handlePeripheralListUpdate',
            'handlePeripheralScanTimeout',
            'handleRefresh',
            'handleChangeIp',
            'handleSerialConnect',
            'handleModemConnect',
            'handleConnectionTypeClick',
            'handleBackPhaseClick'
        ]);
        this.state = {
            scanning: true,
            peripheralList: [],
            connectionPhase: 'connect' // connect, directIp, changeProtocolHTTP, changeProtocolHTTPS
        };
    }
    componentDidMount () {
        this.props.vm.scanForPeripheral(this.props.extensionId);
        this.props.vm.on(
            'PERIPHERAL_LIST_UPDATE', this.handlePeripheralListUpdate);
        this.props.vm.on(
            'PERIPHERAL_SCAN_TIMEOUT', this.handlePeripheralScanTimeout);
    }
    componentWillUnmount () {
        // @todo: stop the peripheral scan here
        this.props.vm.removeListener(
            'PERIPHERAL_LIST_UPDATE', this.handlePeripheralListUpdate);
        this.props.vm.removeListener(
            'PERIPHERAL_SCAN_TIMEOUT', this.handlePeripheralScanTimeout);
    }
    handlePeripheralScanTimeout () {
        this.setState({
            scanning: false,
            peripheralList: []
        });
    }
    handlePeripheralListUpdate (newList) {
        // TODO: sort peripherals by signal strength? so they don't jump around
        const peripheralArray = Object.keys(newList).map(id =>
            newList[id]
        );
        this.setState({peripheralList: peripheralArray});
    }
    handleRefresh () {
        this.props.vm.scanForPeripheral(this.props.extensionId);
        this.setState({
            scanning: true,
            peripheralList: []
        });
    }
    handleChangeIp(ip) {
        this.props.extensionRef.changeIp(ip || this.props.extensionRef._peripheral.connectIP);
        this.forceUpdate();
    }
    handleSerialConnect() {
        this.handleChangeIp('serial');
        this.props.onConnecting();
    }
    handleModemConnect() {
        this.handleChangeIp('modem');
        this.props.onConnecting();
    }
    checkWrongSite(site) {
        return window.location.hostname === site && !this.props.vm.runtime.developmentMode
    }
    handleBackPhaseClick() {
        this.setState({connectionPhase: 'connect'});
    }
    handleConnectionTypeClick(type) {
        const canUseWifi = !this.checkWrongSite('scratchlinkblocks.au');
        const canUseSerial = !this.checkWrongSite('scratchlinkblockswifi.au');

        if(canUseWifi) {
            if(type === 'wifi') {
                this.handleChangeIp('192.168.5.1');
                this.setState({connectionPhase: 'directIp'});
            }
            else if(type === 'hotspot') {
                this.handleChangeIp('192.168.5.1');
                this.props.onConnecting();
            }
        } else {
            if(type === 'wifi') this.setState({connectionPhase: 'changeProtocolHTTP'});
            else if(type === 'hotspot') this.setState({connectionPhase: 'changeProtocolHTTP'});
        }

        if(canUseSerial) {
            if(type === 'serial') this.handleSerialConnect();
            else if(type === 'modem') this.handleModemConnect();
        } else {
            if(type === 'serial') this.setState({connectionPhase: 'changeProtocolHTTPS'});
            else if(type === 'modem') this.setState({connectionPhase: 'changeProtocolHTTPS'});
        }
    }
    render () {
        return (
            <ScratchLinkScanningStepComponent
                peripheralList={this.state.peripheralList}
                phase={this.state.phase}
                scanning={this.state.scanning}
                smallPeripheralImage={this.props.smallPeripheralImage}
                title={this.props.extensionId}
                onCancel={this.props.onCancel}
                onConnected={this.props.onConnected}
                onConnecting={this.props.onConnecting}
                onRefresh={this.handleRefresh}
                extensionRef={this.props.extensionRef}
                handleChangeIp={this.handleChangeIp}
                handleSerialConnect={this.handleSerialConnect}
                handleModemConnect={this.handleModemConnect}
                handleConnectionTypeClick={this.handleConnectionTypeClick}
                handleBackPhaseClick={this.handleBackPhaseClick}
                connectionPhase={this.state.connectionPhase}
            />
        );
    }
}

ScratchLinkScanningStep.propTypes = {
    extensionId: PropTypes.string.isRequired,
    onConnected: PropTypes.func.isRequired,
    onConnecting: PropTypes.func.isRequired,
    handleConnecting: PropTypes.func,
    smallPeripheralImage: PropTypes.string,
    vm: PropTypes.instanceOf(VM).isRequired,
    extensionRef: PropTypes.object
};

export default ScratchLinkScanningStep;
